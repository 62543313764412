
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Home from './Home';





function App() {
  return (
    <>
 <Home/>
   
   </>)
}

export default App;
